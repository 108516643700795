var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "removePaymentMethodModal",
      attrs: {
        id: "remove-payment-method-modal",
        "data-cy": "invite-people-modal",
        size: "xl",
        "hide-footer": "",
        title: _vm.modalTitle,
      },
      on: { hidden: _vm.onClose },
    },
    [
      _vm.loaded
        ? _c(
            "div",
            [
              _vm.servicesUsingThisPaymentMethod.length === 0
                ? _c("div", [
                    _c("p", [
                      _vm._v(
                        "Are you sure you want to remove this payment Method?"
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    [
                      _c("p", [
                        _vm._v(
                          "\n        This " +
                            _vm._s(_vm.paymentType) +
                            " is attached to a service that requires a payment method on file for automatic billing.\n        Please assign another saved payment method below.\n        If you need to add a new payment method, please return to Payment Methods under your account settings first.\n      "
                        ),
                      ]),
                      _c("ct-table", {
                        staticClass: "remove-service-table",
                        attrs: {
                          striped: "",
                          fields: _vm.tableFields,
                          items: _vm.servicesUsingThisPaymentMethod,
                          "is-paginated": false,
                          loaded: _vm.loaded,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(description)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(data.item.product.description) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(required)",
                              fn: function (data) {
                                return [
                                  data.item.subscription_id
                                    ? _c("span", [
                                        _vm._v(
                                          "\n            Card Required\n          "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v("\n            -\n          "),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(action)",
                              fn: function (data) {
                                return [
                                  data.item
                                    ? _c("b-select", {
                                        attrs: {
                                          options: _vm.alternatePaymentMethods,
                                        },
                                        model: {
                                          value:
                                            _vm.actionsSelected[data.item.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.actionsSelected,
                                              data.item.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "actionsSelected[data.item.id]",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3832857234
                        ),
                      }),
                    ],
                    1
                  ),
              _c(
                "b-button-group",
                { staticClass: "float-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        variant: "danger",
                        "aria-label": "cancel button",
                      },
                      on: { click: _vm.hideModal },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "aria-label": "proceed button",
                        disabled:
                          _vm.disabledIfSubscriptionDoesNotHavePaymentMethod,
                      },
                      on: { click: _vm.submitDecisions },
                    },
                    [_vm._v("\n        Proceed\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }