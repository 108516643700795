<template>
  <b-img :src="isExpired ? expiredSrc : expiredSoonSrc" />
</template>

<script>
export default {
  name: 'ExpiredCardIcon',
  props: {
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expiredSrc: '/images/payments/message-alert-triangle.svg',
      expiredSoonSrc: '/images/payments/message-warning-triangle.svg',
    }
  },
}
</script>
