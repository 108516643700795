<template>
  <b-modal
    id="remove-payment-method-modal"
    ref="removePaymentMethodModal"
    data-cy="invite-people-modal"
    size="xl"
    hide-footer
    :title="modalTitle"
    @hidden="onClose"
  >
    <div v-if="loaded">
      <div v-if="servicesUsingThisPaymentMethod.length === 0">
        <p>Are you sure you want to remove this payment Method?</p>
      </div>
      <div v-else>
        <p>
          This {{ paymentType }} is attached to a service that requires a payment method on file for automatic billing.
          Please assign another saved payment method below.
          If you need to add a new payment method, please return to Payment Methods under your account settings first.
        </p>

        <ct-table
          striped
          :fields="tableFields"
          :items="servicesUsingThisPaymentMethod"
          :is-paginated="false"
          :loaded="loaded"
          class="remove-service-table"
        >
          <template v-slot:cell(description)="data">
            {{ data.item.product.description }}
          </template>
          <template v-slot:cell(required)="data">
            <span v-if="data.item.subscription_id">
              Card Required
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template v-slot:cell(action)="data">
            <b-select
              v-if="data.item"
              v-model="actionsSelected[data.item.id]"
              :options="alternatePaymentMethods"
            />
          </template>
        </ct-table>
      </div>

      <b-button-group class="float-right">
        <b-button
          variant="danger"
          class="mr-2"
          aria-label="cancel button"
          @click="hideModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          aria-label="proceed button"
          :disabled="disabledIfSubscriptionDoesNotHavePaymentMethod"
          @click="submitDecisions"
        >
          Proceed
        </b-button>
      </b-button-group>
    </div>
    <ct-centered-spinner v-else />
  </b-modal>
</template>

<script>
  import TokenizerClient from '@/common/modules/tokenizer-client'
  import axiosClient from '../http'
  import CtTable from './shared/CtTable'
  import CtCenteredSpinner from './shared/CtCenteredSpinner'
  import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'

  export default {
    name: 'RemovePaymentMethodModal',
    components: {
      CtTable,
      CtCenteredSpinner,
    },
    mixins: [paymentMethodsMixin],
    props: {
      payableId: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        loaded: false,
        actionsSelected: {},
        servicesUsingThisPaymentMethod: [],
        tokenizerClient: new TokenizerClient(this.$store),
        alternatePaymentMethods: [],
      }
    },

    computed: {
      paymentType() {
        return this.isTypeCard ? 'card' : 'bank account'
      },
      modalTitle() {
        let title = `Preparing to Remove Payment Method`
        if (this.currentPaymentMethod) {
          if (this.isTypeCard) {
            title = `Preparing to Remove Card Ending in ${this.currentPaymentMethod.last4}`
          }
          if (this.isTypeACH) {
            title = `Preparing to Remove ACH Ending in ${this.currentPaymentMethod.bank_account_number.slice(-2)}`
          }
        }

        return title
      },

      disabledIfSubscriptionDoesNotHavePaymentMethod() {
        let disabled = false

        for (let service of this.servicesUsingThisPaymentMethod) {
          const subscriptionId = service.subscription_id
          const payableId = this.actionsSelected[service.id]
          if (subscriptionId != null && payableId == null) {
            disabled = true
          }
        }

        return disabled
      },

      hasSubscriptionService() {
        return !!this.servicesUsingThisPaymentMethod.find(service => service.subscription_id != null)
      },

      currentPaymentMethod() {
        let paymentMethod = null
        if (this.isTypeCard) {
          paymentMethod = this.cards.find(card => card.id === this.payableId)
        }
        if (this.isTypeACH) {
          paymentMethod = this.automatedClearingHouses.find(ach => ach.id === this.payableId)
        }

        return paymentMethod
      },

      tableFields() {
        const fields = [
          { key: 'company.name', label: 'Company' },
          { key: 'product.name', label: 'Service' },
          { key: 'required', label: 'Required' },
          { key: 'action', label: 'Action' },
        ]

        if (!this.hasSubscriptionService) {
          delete fields[2]
        }

        return fields
      },
    },

    watch: {
      async payableId(currentPayableId) {
        if (currentPayableId) {
          this.loaded = false

          // Gather the list of AutoPay services that require this cards existence.
          const response = await axiosClient.get(`client/services/by_autopay_payable_id/${currentPayableId}`, { params: { limit: 5000 } })
          this.servicesUsingThisPaymentMethod = response.data.result || []

          this.createOptions()

          this.loaded = true
        }
      },
    },

    methods: {
      // Create the list of available actions. Users must choose a different paymentMethod, or cancel the service entirely.
      createOptions() {
        this.alternatePaymentMethods = []
        this.actionsSelected = {}

        // Add each card to the list.
        if (this.cards.length > 0) {
          this.alternatePaymentMethods.push(...this.cards.map(card => {
            return { value: card.id, text: 'Select card ending in ' + card.last4 }
          }))
        }

        // Add each ach to the list.
        if (this.automatedClearingHouses.length > 0) {
          this.alternatePaymentMethods.push(...this.automatedClearingHouses.map(ach => {
            return { value: ach.id, text: 'Select ach ending in ' + ach.bank_account_number.slice(-2) }
          }))
        }

        // Filter out the payment method that the user is attempting to delete. It is not a valid replacement option.
        this.alternatePaymentMethods = this.alternatePaymentMethods.filter(paymentMethod => this.currentPaymentMethod && paymentMethod.value !== this.currentPaymentMethod.id)

        this.alternatePaymentMethods.push({ value: null, text: 'No payment method' })

        // Default the selection to the first available card. If no cards, choose null.
        // eslint-disable-next-line no-undef
        let defaultAction = _.get(this.alternatePaymentMethods, '[0].value', null)

        // Select an action for each service that depends on the card being deleted.
        this.servicesUsingThisPaymentMethod.map(service => {
          this.$set(this.actionsSelected, service.id, defaultAction)
        })
      },

      hideModal() {
        this.$refs.removePaymentMethodModal.hide()
      },

      async submitDecisions() {
        this.loaded = false

        await this.transferServices()
        await this.tokenizerClient.destroyPaymentMethod(this.payableId)
        await this.logExpiredCardInteraction('delete', this.payableId)

        this.loaded = true

        // Return control to whatever summoned this modal.
        this.$emit('update', this.payableId, null)
      },

      async transferServices() {
        // Iterate over the actions that the user decided on for each service. Submit each.
        const promises = []
        for (let serviceId in this.actionsSelected) {
          const service = this.servicesUsingThisPaymentMethod.find(service => service.id === serviceId)
          const payableId = this.actionsSelected[serviceId]

          // eslint-disable-next-line no-undef
          const companyId = _.get(service, 'company.id', null)
          const params = {
            id: service.id,
            autopay_payable_id: payableId,
          }

          const promise = axiosClient.post(`client/companies/${companyId}/services/${service.id}/set_autopay_id`, params)
          promises.push(promise)
        }

        await Promise.all(promises)
      },
      onClose() {
        this.$emit('close')
      },
    },
  }
</script>
<style lang="scss">

  .remove-service-table {
    color: #363636;
    margin-bottom: 56px;

    th {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 214.286% */
      border-top: 1px solid #D0D0D0 !important;
      border-bottom: 1px solid #D0D0D0 !important;
      background: #F5F6F9 !important;
    }

    td {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 187.5% */
      border: none !important;
    }

    .custom-select {
      min-height: 41px !important;
      height: 41px !important;
      border-radius: 6px;
    }

    // custom striped styling
    .table-striped tbody tr:nth-of-type(odd) {
      background: #FFF;
    }

    .table-striped tbody tr:nth-of-type(even) {
      background: #F5F6F9;
    }

    // custom column adjustments to align each
    .table.b-table > thead > tr > th[aria-colindex="1"] {
      width: 37.5% !important;
    }

    .table.b-table > thead > tr > th[aria-colindex="2"] {
      width: 37.5% !important;
    }

    .table.b-table > thead > tr > th[aria-colindex="3"]:last-child {
      font-size: 0;
      width: 25% !important;
    }
  }
</style>
