import {
  formatPhone,
  formatDateString,
  formatDateFriendly,
  formatDateTimeLong,
  formatCCExpiration,
} from '@/common/modules/formatters'

/*
* Dev notes:
* Directives give us a simple way to tell dom elements how to format their internal content.
* Conceptually its similar to a pipe in angular (but now the formatting rule goes on the left).
*
* Examples:
* <p v-phone-number="5091112222"/>      generates   <p>509-111-2222</p>
* <p v-phone-number="+15091112222"/>    generates   <p>+1 (509) 111-2222</p>
* <p v-date-label="milliTimestamp"/>    generates   <p>Oct 27, 2020</p>
*
* Reference:
* https://012.vuejs.org/guide/directives.html
* https://vuejs.org/v2/guide/custom-directive.html
*
* */

const replaceDateString = (el, binding) => el.textContent = formatDateString(binding.value)
const replaceDateFriendly = (el, binding) => el.textContent = formatDateFriendly(binding.value)
const replaceDateTimeLong = (el, binding) => el.textContent = formatDateTimeLong(binding.value)
const replacePhone = (el, binding) => el.textContent = formatPhone(binding.value)
const replaceCC = (el, binding) => el.textContent = formatCCExpiration(binding.value)


export const dateLabel = { inserted: replaceDateString, update: replaceDateString }
export const friendlyDate = { inserted: replaceDateFriendly, update: replaceDateFriendly }
export const datetimeLong = { inserted: replaceDateTimeLong, update: replaceDateTimeLong }
export const phoneNumber = { inserted: replacePhone, update: replacePhone }
export const ccExpiration = { bind: replaceCC }
