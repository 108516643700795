var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-img", {
    attrs: { src: _vm.isExpired ? _vm.expiredSrc : _vm.expiredSoonSrc },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }