<template>
  <b-container
    class="payment-method-options"
    :class="isShowSelectRadio ? 'has-radio-col' : 'no-radio-col'"
  >
    <div class="table-header">
      <header>Credit/Debit Cards</header>
      <b-button
        variant="outline-primary"
        data-cy="addCardBtn"
        class="add-button-primary"
        aria-label="Add Card button"
        @click="showModal('payment-method-modal', null, 'card', false)"
      >
        <div class="d-flex justify-content-center">
          <span>+ Add Card</span>
        </div>
      </b-button>
    </div>
    <b-table
      :items="cards"
      :fields="cardFields"
      :sticky-header="true"
    >
      <template v-if="isShowSelectRadio" v-slot:cell(select)="data">
        <b-form-radio
          v-model="selection"
          :value="data.item.id"
          :disabled="data.item.expired"
          @change="setPaymentMethod({ payable: data.item, type: 'card' })"
        />
      </template>
      <template v-slot:cell(cardNumber)="data">
        <b-img :src="cardLogo(data.item.brand)" class="mr-2" />
        <div class="d-inline-flex">
          <span class="mask-extended">**** **** **** {{ data.item.last4 }}</span>
          <span class="mask-limited">** {{ data.item.last4 }}</span>
        </div>
      </template>
      <template v-slot:cell(exp)="data">
        <div :class="isPaymentMethodsPage ? 'd-flex expired-card-dynamic-flex' : ''">
          <span v-cc-expiration="{month: data.item.exp_month, year: data.item.exp_year}" class="mr-1" />
          <expired-card-label class="ml-1" :card="data.item" />
        </div>
      </template>
      <template v-slot:cell(name)="data">
        {{ `${startCase(data.item.first_name)} ${startCase(data.item.last_name)}` }}
      </template>
      <template v-if="isShowActions" v-slot:head(actions) />
      <template v-if="isShowActions" v-slot:cell(actions)="row">
        <div class="pt-2 d-flex dynamic-justify-content-start justify-content-end align-items-center">
          <b-button
            class="action-button mr-2"
            variant="link"
            aria-label="edit button"
            @click="showModal('payment-method-modal', row.item, 'card', true)"
          >
            <feather-icon type="pencil" width="18" height="18" />
          </b-button>
          <b-button
            class="action-button mx-2"
            variant="link"
            aria-label="delete button"
            @click="showModal('remove-payment-method-modal', row.item, 'card', false)"
          >
            <feather-icon type="trash" width="18" height="18" />
          </b-button>
        </div>
      </template>
    </b-table>
    <div v-if="isPurchaseCanaryWebsite">
      <ach-limit-label />
      <div class="table-header">
        <div class="table-header-label">
          <header>ACH/Bank Transfer</header>
          <b-img v-if="exceedsAchTransactionLimit" src="/images/payments/message-warning-triangle.svg" />
        </div>
        <b-button
          variant="outline-primary"
          data-cy="addCardBtn"
          class="add-button-primary"
          aria-label="Add Bank button"
          @click="showModal('payment-method-modal', null, 'automatedClearingHouse', false)"
        >
          + Add ACH
        </b-button>
      </div>
      <b-table
        :items="automatedClearingHouses"
        :fields="achFields"
        :sticky-header="true"
      >
        <template v-slot:head(select) />
        <template v-if="isShowSelectRadio" v-slot:cell(select)="data">
          <div :class="exceedsAchTransactionLimit ? 'disabled-input' : ''">
            <b-form-radio
              v-model="selection"
              :value="data.item.id"
              :disabled="exceedsAchTransactionLimit"
              @change="setPaymentMethod({ payable: data.item, type: 'automatedClearingHouse' })"
            />
          </div>
        </template>
        <template v-slot:cell(accountNumber)="data">
          <b-img src="/images/automatedClearingHouses/ach.svg" class="mr-2" />
          <span class="mask-extended">************</span>
          <span class="mask-limited">**</span>
          {{ data.item.bank_account_number.slice(-2) }}
        </template>
        <template v-slot:cell(accountType)="data">
          {{ data.item.bank_account_type }}
        </template>
        <template v-slot:cell(accountNickname)="data">
          {{ data.item.nickname }}
        </template>
        <template v-if="isShowActions" v-slot:head(actions) />
        <template v-if="isShowActions" v-slot:cell(actions)="row">
          <div class="pt-2 d-flex dynamic-justify-content-start justify-content-end align-items-center">
            <b-button
              class="action-button mr-2"
              variant="link"
              aria-label="edit button"
              @click="showModal('payment-method-modal', row.item, 'automatedClearingHouse', true)"
            >
              <feather-icon type="pencil" width="18" height="18" />
            </b-button>
            <b-button
              variant="link"
              class="action-button mx-2"
              aria-label="delete button"
              @click="showModal('remove-payment-method-modal', row.item, 'automatedClearingHouse', false)"
            >
              <feather-icon type="trash" width="18" height="18" />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>

    <ct-centered-spinner v-if="loadingPaymentMethods" />
    <pay-agreement v-if="!hideAgreement && isShowPayAgreement" style="padding-left: 20px;" />

    <!--add when digit wallets exist-->
    <!--    <div class="table-header">-->
    <!--      <header>Digital Wallet</header>-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        data-cy="addCardBtn"-->
    <!--        @click="showModal('payment-method-modal', null)"-->
    <!--      >-->
    <!--        Add Digital Wallet-->
    <!--      </b-button>-->
    <!--    </div>-->
    <!--    <ct-table-->
    <!--      striped-->
    <!--      :loaded="!loadingPaymentMethods"-->
    <!--      :items="digitalWallets"-->
    <!--      :fields="digitalWalletFields"-->
    <!--      :is-paginated="false"-->
    <!--      caption-top-->
    <!--    >-->
    <!--      <template v-slot:cell(accountType)="data">-->
    <!--        <b-img :src="cardLogo(data.item.brand)" class="mr-2" width="35" />-->

    <!--        &lt;!&ndash;update field when exists&ndash;&gt;-->
    <!--        {{ `${data.item.digitalWalletType}` }}-->
    <!--      </template>-->
    <!--      <template v-slot:cell(accountName)="data">-->

    <!--        &lt;!&ndash;update field when exists&ndash;&gt;-->
    <!--        {{ `${data.item.first_name} ${data.item.last_name}` }}-->
    <!--      </template>-->
    <!--      <template v-slot:cell(actions)="row">-->
    <!--        <b-button variant="link" class="mr-2" @click="showModal('payment-method-modal', row.item)">-->
    <!--          Edit-->
    <!--        </b-button>-->
    <!--        <b-button variant="link" class="text-danger" @click="showModal('remove-payment-method-modal', row.item)">-->
    <!--          Delete-->
    <!--        </b-button>-->
    <!--      </template>-->
    <!--    </ct-table>-->

    <!--    <digital-wallet-survey />-->

    <payment-method-modal
      @badCardIssuer="setBadCardIssuer"
      @close="closeModal"
      @update="paymentMethodAdded"
    />

    <remove-payment-method-modal
      :payable-id="selectedPaymentMethod?.id"
      @close="closeModal"
      @update="paymentMethodRemoved"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import FeatherIcon from '@/components/shared/FeatherIcon'
import PayAgreement from '@/components/Payments/PayAgreement'
import PaymentMethodModal from '@/components/PaymentMethodModal'
import RemovePaymentMethodModal from '@/components/RemovePaymentMethodModal'
import { getCardLogo } from '@/common/modules/cc'
import { ccExpiration } from '@/directives/directives'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import ExpiredCardLabel from '@/components/Payments/ExpiredCards/ExpiredCardLabel'
import AchLimitLabel from '@/components/Payments/AchLimitLabel'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
// import DigitalWalletSurvey from '@/components/Payments/DigitalWalletSurvey'

export default {
  name: 'PaymentMethodOptions',
  components: { CtCenteredSpinner, AchLimitLabel, ExpiredCardLabel, FeatherIcon, PayAgreement, PaymentMethodModal, RemovePaymentMethodModal },
  directives: {
    ccExpiration,
  },
  mixins: [paymentMethodsMixin],
  props: {
    isShowActions: {
      type: Boolean,
      default: false,
    },
    isShowPayAgreement: {
      type: Boolean,
      default: true,
    },
    isShowSelectRadio: {
      type: Boolean,
      default: true,
    },
    total: {
        type: Number,
        default: 0,
    },
  },
  data() {
    return {
      achTableKey: 0,       // incrementing key forces table to re-render, needed for updated payment methods
      cardsTableKey: 1,     // incrementing key forces table to re-render, needed for updated payment methods
      hideAgreement: false, // unmounts pay agreement when payment method form modal is opened to avoid collision of agreement callbacks
      cardFields: ['cardNumber', 'exp', 'name'],
      achFields: ['accountNumber', 'accountType', 'accountNickname'],
      // digitalWalletFields: [
      //   { key: 'accountType', sortable: false },
      //   { key: 'accountName', sortable: false },
      //   { key: 'actions', sortable: false },
      // ],
    }
  },
  computed: {
    selection: {
      get(){
        return this.selectedPaymentMethod?.id
      },
      set(){},
    },
  },
  async mounted() {
    this.resetPaymentMethod()
    this.setCurrentTotal(this.total)
    if (this.isShowSelectRadio) {
      this.cardFields.unshift('select')
      this.achFields.unshift('select')
    }
    if (this.isShowActions) {
      this.cardFields.push({ key: 'actions', label: '' })
      this.achFields.push({ key: 'actions', label: '' })
    }
    await this.retryPaymentMethods()
  },
  methods: {
    cardLogo(brand) {
      return getCardLogo(brand)
    },
    showModal(modalId, paymentMethod, payableType, editMode) {
      this.setPaymentMethod({ payable: paymentMethod, type: payableType })
      this.hideAgreement = true
      this.editMode = editMode
      this.$bvModal.show(modalId)
    },
    closeModal() {
      this.resetPaymentMethod()
      this.hideAgreement = false
    },
    paymentMethodAdded(payableId, errors) {
      this.achTableKey++
      this.cardsTableKey++
      this.$bvModal.hide('payment-method-modal')
      this.displayResultToast(payableId, this.editMode, errors)
    },
    paymentMethodRemoved(payableId, errors) {
      this.$bvModal.hide('remove-payment-method-modal')
      this.displayResultToast(payableId, this.editMode, errors, true)
    },
    startCase: input => _.startCase(input),
  },
}
</script>

<style scoped lang="scss">

.payment-method-options {
  padding-left: 0.125em !important;
  padding-right: 0.125em !important;

  .table-header {
    display: inline-flex;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: .4rem !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    header {
      color: #363636;
      font-size: 1em;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }

    .table-header-label {
      gap: 0.5rem;
      display: flex;
      justify-content: flex-start;
    }
  }

  .payment-number {
    min-width: fit-content;
  }

  .expired-card-dynamic-flex {
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
  }

  ::v-deep .b-table {
    color: #363636;
    margin-bottom: 3.5em;

    .b-table-sticky-header {
      max-height: 18.75em !important;
    }

    input[type=radio].custom-control-input:disabled ~ .custom-control-label {
      cursor: not-allowed;
    }

    .custom-control-label {
      cursor: pointer;
      margin-top: .5rem;
    }

    th {
      font-size: .85rem;
      font-weight: 700;
      border-top: 1px solid #DCDDDD;
      border-bottom: 1px solid #DCDDDD;
      background-color: #F4F4F5 !important;
      height: 40px;
      padding: 0.5rem;
      vertical-align: middle;
    }

    tr {
      border-bottom: 1px solid #DCDDDD;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      font-size: 1em;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 187.5% */
      padding: 0.5rem;
      margin: 0;

      &:hover {
        cursor: default !important;
      }
    }
  }

  // button styling
  .add-button-primary {
    color: #000864 !important;
    border-color: #000864 !important;
    border-radius: 4px !important;
    padding: .5em !important;
    float: right;

    &:hover {
      background-color: #F1F1F2 !important;
    }
  }

  .add-button-link {
    color: #000864;
    margin: .5rem 0 0 0 !important;
    padding: 0 !important;
    float: right;
  }

  .action-button {
    color: #5A5757;
    padding: 0;
  }

  @media only screen and (min-width: 991px) {
    .mask-extended {
      display: inline;
    }

    .mask-limited {
      display: none;
    }
  }

  @media only screen and (max-width: 991px) {
    .expired-card-dynamic-flex {
      align-items: flex-start;
    }

    .mask-extended {
      display: none;
    }

    .mask-limited {
      display: inline;
    }

    .dynamic-justify-content-start {
      justify-content: start !important;
    }
  }
}

@media only screen and (min-width: 991px) {
  .no-radio-col {
    // card number or account number
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="1"] {
      width: 30%;
    }
    // card expiration or ACH account type
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="2"] {
      width: 20%;
    }
    // cardholder name or ACH account nickname
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="3"] {
      width: 30%;
    }
  }
}

.has-radio-col {
  // radio button
  ::v-deep .table.b-table > thead > tr > th[aria-colindex="1"] {
    width: 1%;
  }
  @media only screen and (min-width: 991px) {
    // card number or account number
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="2"] {
      width: 37%;
    }
    // card expiration or ACH account type
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="3"] {
      width: 23%;
    }
    // cardholder name or ACH account nickname
    ::v-deep .table.b-table > thead > tr > th[aria-colindex="4"] {
      width: 39%;
    }
  }
}
</style>
